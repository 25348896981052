import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import MessengerLogo from '../images/contact/Messenger_Logo_Color_RGB.svg'
import WhatsAppLogo from '../images/contact/WhatsApp_Logo_1-01.svg'
import GmailLogo from '../images/contact/gmail.svg'

import './contactModal.css'

Modal.setAppElement('#___gatsby')

function ContactModal({ buttonText, name, messenger, mail, whatsapp }) {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const handleKeyDownClose = event => {
    event.preventDefault()
    if (event.keyCode && event.keyCode === 13) {
      closeModal()
    }
  }

  return (
    <>
      <button type="button" className="contact-btn" onClick={openModal}>
        {buttonText}
      </button>
      <Modal
        className="contact-modal"
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        overlayClassName="contact-modal-overlay"
      >
        <h3 className="contact-modal-title">Contacter {name}</h3>
        <p className="paragraph">
          On est en 2020, il y a plusieurs moyens, alors fais ton choix :
        </p>
        <div className="contact-links">
          <a href={messenger} target="_blank" rel="noopener noreferrer">
            <div className="contact-link-btn">
              <img
                alt="Icône facebook messenger cliquable"
                className="contact-messenger-icon"
                src={MessengerLogo}
              />
              <span>Écrit lui sur messenger</span>
            </div>
          </a>
          {whatsapp ? (
            <a href={whatsapp} target="_blank" rel="noopener noreferrer">
              <div className="contact-link-btn">
                <img
                  alt="Icône WhatsApp cliquable"
                  className="contact-whatsapp-icon"
                  src={WhatsAppLogo}
                />
                <span>Écrit lui sur WhatsApp</span>
              </div>
            </a>
          ) : (
            <div className="contact-link-btn disabled">
              <img
                alt="Icône WhatsApp cliquable"
                className="contact-whatsapp-icon"
                src={WhatsAppLogo}
              />
              <div>
                <span className="contact-btn-stroke">
                  Écrit lui sur WhatsApp
                </span>
                <br />
                <span className="contact-btn-small">
                  (Ah bah non, il a pas...)
                </span>
              </div>
            </div>
          )}
          <a href={mail}>
            <div className="contact-link-btn">
              <img
                alt="Icône email cliquable"
                className="contact-mail-icon"
                src={GmailLogo}
              />
              <span>Envoie lui un email</span>
            </div>
          </a>
        </div>
        <div
          className="contact-modal-close"
          onClick={closeModal}
          onKeyDown={handleKeyDownClose}
          role="button"
          tabIndex={0}
        >
          Fermer X
        </div>
      </Modal>
    </>
  )
}

ContactModal.propTypes = {
  buttonText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  messenger: PropTypes.string.isRequired,
  mail: PropTypes.string.isRequired,
  whatsapp: PropTypes.string,
}

export default ContactModal

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import ContactModal from '../components/contactModal'
import MainLayout from '../components/layouts/MainLayout'
import Footer from '../components/footer'
import SEO from '../components/seo'

import './contact.css'

const CONTACTS_YOHANN = {
  buttonText: 'Le contacter',
  name: 'Yohann',
  mail: 'mailto:yomansk8+wedding@gmail.com',
  messenger: 'https://m.me/yohann.craumacher',
  whatsapp: 'https://wa.me/33631934663',
}
const CONTACTS_CARO = {
  buttonText: 'La contacter',
  name: 'Caro',
  mail: 'mailto:caroline.allouis@gmail.com',
  messenger: 'https://m.me/allouis.caroline',
  whatsapp: 'https://wa.me/33666369349',
}
const CONTACTS_CHLOE = {
  buttonText: 'La contacter',
  name: 'Chloé',
  mail: 'mailto:noret.chloe@gmail.com',
  messenger: 'https://m.me/chlnoret',
  whatsapp: 'https://wa.me/491722897752',
}
const CONTACTS_THOMAS = {
  buttonText: 'Le contacter',
  name: 'Thomas',
  mail: 'mailto:tkaufmann92@yahoo.fr',
  messenger: 'https://m.me/thomas.kaufmann.146',
  whatsapp: 'https://wa.me/33645578754',
}
const CONTACTS_CORALIE = {
  buttonText: 'La contacter',
  name: 'Coralie',
  mail: 'mailto:coralie.louis6041@gmail.com',
  messenger: 'https://m.me/Coco.Louis38',
  whatsapp: 'https://wa.me/33643446123',
}
const CONTACTS_ROBIN = {
  buttonText: 'Le contacter',
  name: 'Robin',
  mail: 'mailto:robin.desforges@gmail.com',
  messenger: 'https://m.me/robin.desforges',
  whatsapp: undefined,
}

function Contact() {
  const data = useStaticQuery(graphql`
    {
      lights: file(relativePath: { eq: "title_lights.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      separator: file(relativePath: { eq: "leaves_separator.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      caro: file(relativePath: { eq: "contact/caro.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      yohann: file(relativePath: { eq: "contact/yohann.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      chloe: file(relativePath: { eq: "contact/chloe.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      coralie: file(relativePath: { eq: "contact/coralie.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      thomas: file(relativePath: { eq: "contact/thomas.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      robin: file(relativePath: { eq: "contact/robin.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <MainLayout activeMenu="contact">
      <SEO title="Des questions ?" />
      <GatsbyImage
        image={data.lights.childImageSharp.gatsbyImageData}
        className="page-title-lights"
        alt="Dessin d'une guirlande de lumières"
      />
      <h1 className="page-title">
        <span className="first-line">Des</span>
        <br />
        <span className="middle-line">QUESTIONS ?</span>
        <br />
        <span className="last-line">(on a pourtant été clairs...)</span>
      </h1>

      <p className="paragraph">
        Si après tout ça, tu te poses encore des questions, tu peux toujours
        nous contacter (ou nos témoins, ils sont aussi là pour ça).
        <br />
        <br />
        Tu trouveras ton bonheur juste en dessous !
      </p>

      <h2 className="page-subtitle brides">Les mariés</h2>
      <GatsbyImage
        image={data.separator.childImageSharp.gatsbyImageData}
        className="separator"
        alt="Couronne de fleur - séparateur"
      />

      <div className="contact-brides-container">
        <div className="contact-container">
          <div className="contact-desc-container">
            <div className="contact-name">
              <span>Caro</span>, dite <span>&quot;Miss Ketchup&quot;</span>
            </div>
          </div>
          <div className="contact-image">
            <GatsbyImage
              image={data.caro.childImageSharp.gatsbyImageData}
              alt="Dessin de Caro, la mariée, dans sa robe avec un serre tête licorne en mangeant des cuisses de poulet avec une banderole indiquant 'ventre sur pattes'."
            />
          </div>
          <ContactModal
            buttonText={CONTACTS_CARO.buttonText}
            mail={CONTACTS_CARO.mail}
            messenger={CONTACTS_CARO.messenger}
            name={CONTACTS_CARO.name}
            whatsapp={CONTACTS_CARO.whatsapp}
          />
        </div>

        <div className="contact-container">
          <div className="contact-desc-container">
            <div className="contact-name">
              <span>Yohann</span>, dit <span>&quot;El cassos&quot;</span>
            </div>
          </div>
          <div className="contact-image">
            <GatsbyImage
              image={data.yohann.childImageSharp.gatsbyImageData}
              alt="Dessin de Yohann, le marié, jouant aux jeux-vidéos dans un costume de dinosaure."
            />
          </div>
          <ContactModal
            buttonText={CONTACTS_YOHANN.buttonText}
            mail={CONTACTS_YOHANN.mail}
            messenger={CONTACTS_YOHANN.messenger}
            name={CONTACTS_YOHANN.name}
            whatsapp={CONTACTS_YOHANN.whatsapp}
          />
        </div>
      </div>

      <h2 className="page-subtitle" style={{ marginTop: '1em' }}>
        Les témoins
      </h2>
      <GatsbyImage
        image={data.separator.childImageSharp.gatsbyImageData}
        className="separator"
        alt="Couronne de fleur - séparateur"
      />

      <div className="contact-witnesses-container">
        <div className="contact-container">
          <div className="contact-desc-container">
            <div className="contact-name">
              <span>Chloé</span>, dite <span>&quot;L&apos;agenda&quot;</span>
            </div>
            <div className="contact-role">rôle : Témoin de la mariée</div>
          </div>
          <div className="contact-image">
            <GatsbyImage
              image={data.chloe.childImageSharp.gatsbyImageData}
              alt="Dessin de Chloé, la témoin de la mariée, en train de préparer un cocktail tout en lisant un livre et parlant Allemand."
            />
          </div>

          <ContactModal
            buttonText={CONTACTS_CHLOE.buttonText}
            mail={CONTACTS_CHLOE.mail}
            messenger={CONTACTS_CHLOE.messenger}
            name={CONTACTS_CHLOE.name}
            whatsapp={CONTACTS_CHLOE.whatsapp}
          />
        </div>

        <div className="contact-container">
          <div className="contact-desc-container">
            <div className="contact-name">
              <span>Coralie</span>, dite <span>&quot;Le maroilles&quot;</span>
            </div>
            <div className="contact-role">rôle : Témoin du marié</div>
          </div>
          <div className="contact-image">
            <GatsbyImage
              image={data.coralie.childImageSharp.gatsbyImageData}
              alt="Dessin de Coralie, la témoin du marié, en train de boire une bière dans sa combinaison de ski avec ses skis sur l'épaule tout en parlant Ch'ti."
            />
          </div>
          <ContactModal
            buttonText={CONTACTS_CORALIE.buttonText}
            mail={CONTACTS_CORALIE.mail}
            messenger={CONTACTS_CORALIE.messenger}
            name={CONTACTS_CORALIE.name}
            whatsapp={CONTACTS_CORALIE.whatsapp}
          />
        </div>

        <div className="contact-container">
          <div className="contact-desc-container">
            <div className="contact-name">
              <span>Thomas</span>, dit <span>&quot;Mister Blue&quot;</span>
            </div>
            <div className="contact-role">rôle : Témoin de la mariée</div>
          </div>
          <div className="contact-image">
            <GatsbyImage
              image={data.thomas.childImageSharp.gatsbyImageData}
              alt="Dessin de Thomas, le témoin de la mariée, dans sa tenue de Stewart en train de jouer avec des avions."
            />
          </div>
          <ContactModal
            buttonText={CONTACTS_THOMAS.buttonText}
            mail={CONTACTS_THOMAS.mail}
            messenger={CONTACTS_THOMAS.messenger}
            name={CONTACTS_THOMAS.name}
            whatsapp={CONTACTS_THOMAS.whatsapp}
          />
        </div>

        <div className="contact-container">
          <div className="contact-desc-container">
            <div className="contact-name">
              <span>Robin</span>, dit <span>&quot;Rabbi Jacob&quot;</span>
            </div>
            <div className="contact-role">rôle : Témoin du marié</div>
          </div>
          <div className="contact-image">
            <GatsbyImage
              image={data.robin.childImageSharp.gatsbyImageData}
              alt="Dessin de Robin, le témoin du marié, avec une grande barbe et un grand chapeau, en train de danser la Hora."
            />
          </div>
          <ContactModal
            buttonText={CONTACTS_ROBIN.buttonText}
            mail={CONTACTS_ROBIN.mail}
            messenger={CONTACTS_ROBIN.messenger}
            name={CONTACTS_ROBIN.name}
            whatsapp={CONTACTS_ROBIN.whatsapp}
          />
        </div>
      </div>
      <Footer />
    </MainLayout>
  )
}

export default Contact
